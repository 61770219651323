import { numAutoValidator } from '@/core'

export const ruleFormAddTimeslot = {
  plate_truck: [
    {
      required: true,
      message: 'Заполните номер авто',
      trigger: 'blur',
    },
    {
      required: true,
      validator: (rule, value, callback) => {
        numAutoValidator(value) ? callback(new Error()) : callback()
      },
      message:
        'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
      trigger: 'blur',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Заполните телефон водителя',
      trigger: 'blur',
    },
  ],
  culture_id: [
    {
      required: true,
      message: 'Выберите культуру',
      trigger: 'change',
    },
  ],
  exporter_id: [
    {
      required: false,
      message: 'Выберите экспортера',
      trigger: 'change',
    },
  ],
  detach: [
    {
      required: true,
      message: 'Выберите состояние',
      trigger: 'change',
    },
  ],
  type: [
    {
      required: true,
      message: 'Выберите тип авто',
      trigger: 'change',
    },
  ],
}

export const paramsList = [
  { id: 1, name: 'Время', prop: 'window_from' },
  { id: 2, name: 'Номер Авто', prop: 'num_auto' },
  { id: 5, name: 'Тип Авто', prop: 'truck_type_code' },
  { id: 3, name: 'Культура', val: 'Ячмень', prop: 'culture_name' },
  { id: 4, name: 'Телефон водителя', prop: 'driver_phone' },
]
