<template>
  <Dialog :name="name" width="475px" class="add-timeslot" @close="beforeClose">
    <template #title>
      <div>Добавление авто</div>
    </template>

    <div>
      <div class="m-b-20">
        <iq-title class-name="mobileHeader">
          Тайм слот: в порядке очереди
        </iq-title>

        <div class="timeslot-wrong__message">
          {{ getMessage }}
        </div>

        <div class="w-100p">
          <div
            v-for="param in paramsList"
            :key="param.id"
            class="timeslot-wrong__param-item"
          >
            <div class="timeslot-wrong__param-title">{{ param.name }}:</div>
            <div class="timeslot-wrong__param-val">
              {{ getDialogParam(param.prop) }}
            </div>
          </div>
        </div>
      </div>

      <el-form
        ref="formAddTimeslot"
        :model="formAddTimeslot"
        :rules="rules"
        label-width="175px"
        class="timeslot-wrong"
        @submit.native.prevent
      >
        <div class="timeslot-wrong__left-section">
          <div class="df jc-fs ai-c m-b-20">
            <iq-input-form
              v-model="formAddTimeslot.plate_truck"
              label="Номер авто"
              label-width="175px"
              input-width="210px"
              prop="plate_truck"
              :rules="rules.plate_truck"
              @keydown.native.enter="handleCloseDialog"
            />

            <div class="form-add-timeslot__btn-wrap df jc-fe">
              <el-button
                class="form-add-timeslot__btn"
                icon="el-icon-search"
                circle
                :disabled="isLoadingPostTimeslots"
                :loading="isLoadingPostTimeslots"
                @click="handleCloseDialog"
              />
            </div>
          </div>

          <iq-select-form
            v-model="formAddTimeslot.culture_id"
            label="Культура"
            prop="culture_id"
            :rules="rules.culture_id"
            :options="cultureList"
            key-label="name"
            key-value="id"
            label-width="175px"
            input-width="265px"
            :is-super-important-label-width="true"
          />

          <iq-select-form
            v-model="formAddTimeslot.exporter_id"
            label="Экспортер"
            prop="exporter_id"
            :rules="rules.exporter_id"
            :options="exportersList"
            disabled
            key-label="name"
            key-value="id"
            label-width="175px"
            input-width="265px"
            :is-super-important-label-width="true"
          />

          <iq-select-form
            v-model="formAddTimeslot.type"
            label="Тип авто"
            prop="type"
            :rules="rules.type"
            :options="autoType"
            key-label="title"
            key-value="val"
            label-width="175px"
            input-width="265px"
            :is-super-important-label-width="true"
          />

          <iq-input-form
            v-model="formAddTimeslot.status"
            label="Статус"
            label-width="175px"
            input-width="265px"
            disabled
            class="m-b-20"
          />

          <iq-input-form
            v-model="formAddTimeslot.phone"
            v-mask="'+7 ### ###-##-##'"
            label="Телефон водителя"
            prop="phone"
            label-width="175px"
            input-width="265px"
            :rules="rules.phone"
            class="m-b-20"
          />

          <iq-select-form
            v-model="formAddTimeslot.detach"
            label="Проезд"
            prop="detach"
            :rules="rules.detach"
            :options="[
              { id: 0, name: 'Разрешен' },
              { id: 1, name: 'Отказан' },
            ]"
            key-label="name"
            key-value="id"
            label-width="175px"
            input-width="265px"
            :is-super-important-label-width="true"
          />
        </div>

        <div class="w-100p m-t-7">
          <div>
            <iq-button
              size="small"
              :disabled="isLoadingPostTimeslots"
              @onClick="handleAddNewTimeslot"
            >
              Добавить авто в порядке очереди
            </iq-button>
          </div>
        </div>
      </el-form>
    </div>
  </Dialog>
</template>

<script>
import {
  CONFIRM_TIMESLOT,
  FETCH_TIMESLOT,
  GET_IS_LOADING_POST_TIMESLOTS,
  POST_TIMESLOT,
} from '@/views/manager/store/actions'
import { DATE_FORMAT_FULL_RU } from '@/constants/date'
import {
  GET_CULTURE_FROM_STATE,
  GET_EXPORTERS_ALL,
} from '@/views/control/store/actions'
import { GET_IS_USE_COMPLEX_PARKING_STATUSES } from '@/store/actions'
import { MANAGER_TIMESLOT_WRONG } from '@/constants/dialogs'
import { autoType } from '@/constants/auto-type'
import { getFormattedTzDate, numAutoValidator } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import {
  paramsList,
  ruleFormAddTimeslot,
} from '@/views/manager/components/dialogs/dialog-timeslot-wrong/data/dialogTimeslotWrong'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/views/ui/components/buttons/IqButton'
import IqInputForm from '@/views/ui/components/input/IqInputForm'
import IqSelectForm from '@/views/ui/components/select/IqSelectForm'
import IqTitle from '@/views/ui/components/typography/IqTitle'
export default {
  name: 'DialogTimeslotWrong',
  components: { IqSelectForm, IqInputForm, IqButton, IqTitle, Dialog },
  data() {
    return {
      name: MANAGER_TIMESLOT_WRONG,
      formAddTimeslot: {
        plate_truck: '',
        culture_id: '',
        exporter_id: '',
        status: 'На парковке',
        phone: '',
        type: '',
        detach: null,
      },
      rules: ruleFormAddTimeslot,
      paramsList,
      autoType,
    }
  },
  computed: {
    ...mapGetters({
      isLoadingPostTimeslots: GET_IS_LOADING_POST_TIMESLOTS,
      cultureList: GET_CULTURE_FROM_STATE,
      exportersList: GET_EXPORTERS_ALL,
      isShowDocumentCheckbox: GET_IS_USE_COMPLEX_PARKING_STATUSES,
    }),
    dialogData() {
      return this.getDialog(this.name)?.data || {}
    },
    isEarly() {
      return this.dialogData?.isEarly
    },
    getMessage() {
      return this.isEarly
        ? 'Существует тайм слот:'
        : 'Водитель опоздал в свой тайм слот:'
    },
  },
  watch: {
    dialogData(val) {
      if (Object.keys(val).length > 0) {
        this.setFormFields(
          val.culture_id,
          val.num_auto,
          val.phone,
          val.truck_type_code,
          val.truck_status === 'detached' ? 1 : 0,
          val.exporter_id,
        )
      }
    },
  },
  methods: {
    ...mapActions({
      fetchTimeslot: FETCH_TIMESLOT,
      createTimeslot: POST_TIMESLOT,
      confirmTimeslot: CONFIRM_TIMESLOT,
    }),
    handleCloseDialog() {
      this.$refs.formAddTimeslot.validateField('plate_truck', () => {
        if (
          this.formAddTimeslot.plate_truck !== '' &&
          !numAutoValidator(this.formAddTimeslot.plate_truck)
        ) {
          this.fetchTimeslot({
            plate_truck: this.formAddTimeslot.plate_truck,
            nameDialog: this.name,
          })
        }
      })
    },
    handleAddNewTimeslot() {
      this.$refs.formAddTimeslot.validate(valid => {
        if (valid) {
          const requestData = {
            timeslot_id: this.dialogData?.id,
            culture_id: this.formAddTimeslot.culture_id,
            exporter_id: this.formAddTimeslot.exporter_id,
            plate_truck: this.formAddTimeslot.plate_truck,
            phone: this.formAddTimeslot.phone,
            detach: this.formAddTimeslot.detach,
            setFormFields: this.setFormFields,
            nameDialog: this.name,
          }

          this.createTimeslot(requestData)
        }
      })
    },
    handleConfirmTimeslot() {
      this.setFormFields()
      this.setDialog({ name: this.name })
    },
    setFormFields(
      culture_id = '',
      plate_truck = '',
      phone = '',
      type = '',
      detach = null,
      exporter_id = '',
    ) {
      this.formAddTimeslot.culture_id = culture_id
      this.formAddTimeslot.plate_truck = plate_truck
      this.formAddTimeslot.phone = phone
      this.formAddTimeslot.type = type
      this.formAddTimeslot.detach = detach
      this.formAddTimeslot.exporter_id = exporter_id
    },
    getDialogParam(prop) {
      if (prop === 'truck_type_code') {
        return autoType.filter(item => item.val === this.dialogData[prop])[0]
          ?.title
      }
      if (prop === 'window_from') {
        return getFormattedTzDate(this.dialogData[prop], DATE_FORMAT_FULL_RU)
      }

      return this.dialogData[prop] || 'не известно'
    },
    beforeClose() {
      this.setFormFields()
      this.$refs.formAddTimeslot.clearValidate()
    },
  },
}
</script>

<style lang="sass">
.timeslot-wrong
  display: flex
  justify-content: space-between
  align-items: flex-start
  flex-wrap: wrap
  padding-bottom: 15px

  &__left-section,
  &__right-section
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    justify-content: flex-start
    flex: 0 0 50%
    &--btn
      justify-content: center

  &__timeslot-wrap-btn
    flex: 0 0 100%
    display: flex
    justify-content: center

  &__btn-wrap
    margin-left: 30px
  &__btn
    font-size: 28px
    cursor: pointer
    color: $color-black
    transition: all .3s ease
    padding: 0 !important
    border: none
    &:hover, &:focus
      color: $btn-orange
      background: transparent

  &__message
    color: $color-red
    margin-bottom: $space-16
    font-weight: bold

  &__param-item
    flex: 0 0 100%
    display: flex
    justify-content: flex-start
    align-items: center
    & + &
      margin-top: 10px

  &__param-title
    flex: 0 0 40%
    font-weight: bold

  &__param-val
    flex: 0 0 60%

  .el-form-item__content
    position: static

  .el-form-item
    position: relative

  .iq-input-form.el-form-item
    margin-bottom: 0 !important
    &.m-b-20
      margin-bottom: 20px !important
  .iq-select-form.el-form-item
    margin-bottom: 20px !important

  .iq-select-form.el-form-item .el-form-item__label
    //line-height: 40px !important // sorry(((
</style>
